import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/storage";
import "firebase/functions";
import "firebase/performance";

import { functions } from "firebase/app";

var firebaseApp = firebase;

export const firebaseConfig = {
  apiKey: "AIzaSyB9dmR7v-GAta6dzD6TVDme_ZPQ_ICFNbw",
  authDomain: "veertly-9177f.firebaseapp.com",
  databaseURL: "https://veertly-9177f.firebaseio.com",
  projectId: "veertly-9177f",
  storageBucket: "veertly-9177f.appspot.com",
  messagingSenderId: "836526950865",
  appId: "1:836526950865:web:aa4f2590ca4283627a78f0"
};
// Initialize Firebase
if (firebase.apps.length === 0) {
  firebaseApp.initializeApp(firebaseConfig);
}
export default firebaseApp;

const perf = firebase.performance();

const functionsApp = functions();
const loginInEvent = functionsApp.httpsCallable("loginInEvent");

if (process.env.NODE_ENV === "development") {
  functionsApp.useFunctionsEmulator("http://localhost:5001");
}

const setUserRoleFunc = functionsApp.httpsCallable("setUserRole");
const unsetUserRoleFunc = functionsApp.httpsCallable("unsetUserRole");

const getTimestampFromDate = (date) => {
  return firebaseApp.firestore.Timestamp.fromDate(date);
};

export {
  loginInEvent,
  getTimestampFromDate,
  perf,
  setUserRoleFunc,
  unsetUserRoleFunc
};